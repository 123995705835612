import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Self-invoke opening animation
(function openingAnimation() {
    let timeline = gsap.timeline();
    timeline.set("header", { visibility: "visible" }); // avoid flickering of navbar before transition
    timeline.set("#intro", { visibility: "visible" }); // avoid flickering of div#intro before transition
    timeline.set(".colored-songs-container", { visibility: "visible" }); // avoid flickering of div#intro before transition
    timeline.set(".colored-songs-menu", { visibility: "visible" }); // avoid flickering of colored-songs-menu before transition

    timeline
        .from("#intro p", {
            autoAlpha: 0,
            y: 8,
            duration: 0.6,
            ease: "ease.out"
        }, 0)
        .from("#intro h2", {
            autoAlpha: 0,
            y: 16,
            duration: 0.6,
            ease: "ease.out"
        }, 1.2)
        .from(".logo", {
            autoAlpha: 0,
            x: -16,
            duration: 0.6,
            ease: "ease.out"
        }, 1.8)
        .from("header h1", {
            autoAlpha: 0,
            y: -16,
            duration: 0.6,
            ease: "ease.out"
        }, 1.8)
        .from(".colored-songs-container", {
            autoAlpha: 0,
            y: 100,
            duration: 0.6,
            ease: "ease.out"
        }, 2.4)
        .from(".scroll-arrow", {
            autoAlpha: 0,
            duration: 0.6,
            y: -16,
            ease: "ease.in"
        }, 3)
        .to(".scroll-arrow", {
            autoAlpha: 0,
            duration: 0.6,
            y: +16,
            ease: "ease.out"
        }, 3.6)
})();

gsap.to("a.rotated", {
    scrollTrigger: {
        trigger: "#about-me",
        start: "top 75%",   // transform header when top of #about-me passes 75% of viewport
        end: "bottom 100%", // if bottom of border-box of about-me passes 100% of viewport
        scrub: true
    },
    autoAlpha: 0.7,
    y: 20
});

gsap.to("header", {});

// Animate div background-color on scroll top
gsap.from(".background-color", {
    scrollTrigger: {
        trigger: "#about-me",
        start: "top 75%",
        end: "bottom 100%",
        scrub: true
    },
    autoAlpha: 0.6
});

gsap.to(".background-color", {
    scrollTrigger: {
        trigger: "#contact",
        start: "top 75%",
        end: "top 25%",
        scrub: true
    },
    opacity: 0.6,
    immediateRender: false
});

// Animate work items on scroll
let workItems = gsap.utils.toArray('.work-item')
workItems.forEach((item, index) => {

    let exptl = gsap.timeline({
        scrollTrigger: {
            trigger: item,
            start: "top 75%",
            end: "top 25%",
            once: true
        }
    });
    exptl.from(item, {
        x: 16,
        opacity: 0,
        duration: 1
    });
});